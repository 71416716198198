var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { outlined: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "three-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("div", { staticClass: "text-overline mb-4" }, [
                            _vm._v(_vm._s(_vm.getCurrentFirma.id)),
                          ]),
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 mb-1" },
                            [
                              _vm.isFirmenArray
                                ? _c("v-select", {
                                    attrs: {
                                      "item-text": "name",
                                      items: _vm.getFirmenList,
                                      value: _vm.getCurrentFirma,
                                      "return-object": "",
                                    },
                                    on: { change: _vm.setCurrentFirma },
                                  })
                                : _c("div", [
                                    _vm._v(_vm._s(_vm.getCurrentFirma.name)),
                                  ]),
                            ],
                            1
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getCurrentFirma.strasse) +
                                " " +
                                _vm._s(_vm.getCurrentFirma.hausnummer) +
                                _vm._s(_vm.getCurrentFirma.hausnummer_zusatz)
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.getCurrentFirma.plz + "").padStart(
                                    5,
                                    "0"
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.getCurrentFirma.ort) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { outlined: "" } },
                [
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsnummer")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.aktuelleVertragsnamen,
                                        "item-text": "Vertrag",
                                        "single-line": "",
                                      },
                                      model: {
                                        value: _vm.aktuellerVertragsname,
                                        callback: function ($$v) {
                                          _vm.aktuellerVertragsname = $$v
                                        },
                                        expression: "aktuellerVertragsname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsart")]),
                                _vm.aktuellerVertrag
                                  ? _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.aktuellerVertrag.vertragsart
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Energieart")]),
                                _vm.aktuellerVertrag
                                  ? _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.aktuellerVertrag.commodity
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsbeginn")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.aktuellerVertrag.vertragsbeginn
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsende")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.aktuellerVertrag.vertragsende
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { outlined: "" } },
                [
                  _c("v-card-title", [_vm._v("Tranchen: ")]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-simple-table", {
                            attrs: { dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("tbody", [
                                      _vm.$store.state.contract
                                        .aktuelleTranchenzeitraeume.length > 1
                                        ? _c("tr", [
                                            _c("td", [_vm._v("Zeitraum")]),
                                            _c("td", [_vm._v(" Zeitraum ")]),
                                          ])
                                        : _vm._e(),
                                      _c("tr", [
                                        _c("td", [_vm._v("Laufzeit von")]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.aktuellerVertrag
                                                  .vertragsbeginn
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("Laufzeit bis")]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.aktuellerVertrag
                                                  .vertragsende
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            " aktueller Preis Einzeltranche pro MWh (" +
                                              _vm._s(_vm.priceDate) +
                                              ") "
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " Base x Baseprice €/MWh + Strukturkonstante €/MWh = Endpreis €/MWh "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c("v-simple-table", {
                            attrs: { "fixed-header": "", dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Tranche")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [
                                            _vm._v("Energievolumen (kWh)"),
                                            _c("br"),
                                            _c(
                                              "span",
                                              { staticClass: "font-italic" },
                                              [_vm._v("gerundet auf kWh")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Kosten der Tranche (€)")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Preis (€/MWh)")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Bestellstatus")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.sortedTranchen,
                                        function (tranchennummer) {
                                          return _c(
                                            "tr",
                                            { key: tranchennummer },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(tranchennummer) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.computedTranches[
                                                        tranchennummer
                                                      ].tranchenmenge.toLocaleString(
                                                        "de-DE",
                                                        {
                                                          maximumFractionDigits: 0,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.computedTranches[
                                                        tranchennummer
                                                      ].tranchenerloes.toLocaleString(
                                                        "de-DE",
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (
                                                        _vm.computedTranches[
                                                          tranchennummer
                                                        ].durchschnittspreis *
                                                        10
                                                      ).toLocaleString(
                                                        "de-DE",
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.computedTranches[
                                                        tranchennummer
                                                      ].status
                                                    ) +
                                                    " ( " +
                                                    _vm._s(
                                                      _vm.convertDatetime(
                                                        _vm.computedTranches[
                                                          tranchennummer
                                                        ].bestellzeitpunkt
                                                      )
                                                    ) +
                                                    ") "
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c("tfoot", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [_vm._v("Summe")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.summedUpTranches[
                                                  "tranchenmenge"
                                                ]
                                              ) + " kWh"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.summedUpTranches[
                                                  "tranchenerloes"
                                                ]
                                              ) + " € Kosten bisher "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.summedUpTranches[
                                                    "durchschnittspreis"
                                                  ]
                                                ) +
                                                " mittlerer Preis (€/MWh) "
                                            ),
                                          ]
                                        ),
                                        _c("th", {
                                          staticClass:
                                            "text-left grey lighten-4",
                                        }),
                                      ]),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", md: "6" } }),
        ],
        1
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }